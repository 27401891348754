<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="正在保存，请稍等。。。"
    element-loading-spinner="el-icon-loading"
  >
    <div class="crt-content" style="background: #fff">
      <h3 style="margin-left: 20px; display: flex; align-items: center">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-edit"
          class="commonBtn"
          @click="changeBasicInfo"
        >
          {{ isResearch ? '编辑' : '取消' }}
        </el-button>
        <el-button
          v-if="!isResearch"
          :disabled="disabled"
          type="success"
          size="mini"
          icon="el-icon-edit"
          class="commonBtn"
          @click="saveBasicInfo"
        >
          保存
        </el-button>
      </h3>
    </div>
    <div class="content-box">
      <div class="component">
        <div class="list">
          <div class="basic-div">
            <div class="select-title">死亡日期：</div>
            <div class="radio">
              <el-date-picker
                :class="
                  queryDeathEventInfo.deathDate &&
                  queryDeathEventInfo.deathDate !== isEditInfo.deathDate
                    ? 'editStyle'
                    : ''
                "
                v-model="queryDeathEventInfo.deathDate"
                type="date"
                placeholder="选择日期"
                clearable
                align="center"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                :disabled="isResearch"
              ></el-date-picker>
            </div>
          </div>
          <div class="deep"></div>

          <div class="deep"></div>
          <div class="deep"></div>

          <div class="basic-div">
            <div class="select-title">死亡原因：</div>
            <div class="radio">
              <el-input
                :class="
                  queryDeathEventInfo.deathCause &&
                  queryDeathEventInfo.deathCause !== isEditInfo.deathCause
                    ? 'textareaEditStyle'
                    : ''
                "
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                maxlength="100"
                placeholder="请输入"
                v-model="queryDeathEventInfo.deathCause"
                :disabled="isResearch"
              ></el-input>
            </div>
          </div>
          <div class="deep"></div>

          <div class="basic-div">
            <div class="select-title">备注：</div>
            <div class="radio radio-textarea">
              <el-input
                :class="
                  queryDeathEventInfo.note && queryDeathEventInfo.note !== isEditInfo.note
                    ? 'textareaEditStyle'
                    : ''
                "
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                maxlength="100"
                placeholder="请输入"
                v-model="queryDeathEventInfo.note"
                :disabled="isResearch"
              ></el-input>
            </div>
          </div>
          <div class="deep"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deepClone from '../../../../../plugins/clone'
export default {
  name: 'DeathEvents',
  components: {},
  data() {
    return {
      isResearch: true,
      deathTypeList: [
        { id: 1, title: '心源性死亡' },
        { id: 2, title: '非心源性死亡' }
      ],
      queryDeathEventInfo: {
        deathDate: null, //死亡日期
        note: null, //备注
        // deathType: null, //死亡类型：心源性死亡，非心源性死亡
        deathCause: null //死亡原因
      },
      isEditInfo: {
        deathDate: null, //死亡日期
        note: null, //备注
        // deathType: null, //死亡类型：心源性死亡，非心源性死亡
        deathCause: null //死亡原因
      },
      postOperativeSurvivalTime: null,
      disabled: false,
      type: 'primary',
      loading: false
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.patientIndex) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  computed: {},
  mounted() {},
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    initData() {
      if (!this.info.patientIndex) return
      this.$api
        .post(`/v1/webconsole/death/query/patient/${this.info.patientIndex}`)
        .then(({ data: res }) => {
          if (res && res.data) {
            this.queryDeathEventInfo = res.data
            this.isEditInfo = deepClone(this.queryDeathEventInfo)
          }
        })
    },
    changeBasicInfo() {
      this.disabled = false
      this.loading = false
      this.type = 'primary'
      this.isResearch = !this.isResearch
    },
    saveBasicInfo() {
      this.disabled = true
      this.loading = true
      this.type = 'info'
      this.$api
        .post(`/v1/webconsole/death/save/deathEvent/${this.info.patientIndex}`, {
          ...this.queryDeathEventInfo
        })
        .then((res) => {
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.isEditInfo = deepClone(this.queryDeathEventInfo)
          if (res.data.msg === '清除死亡事件') {
            this.queryDeathEventInfo = this.$options.data().queryDeathEventInfo
          }
        })
        .finally(() => {
          this.changeBasicInfo()
        })
    }
    // selectRadio(ele) {
    //   if (this.isResearch) return false
    //   if (this.queryDeathEventInfo.deathType == ele.title) {
    //     return (this.queryDeathEventInfo.deathType = '')
    //   }
    //   this.queryDeathEventInfo.deathType = ele.title
    // }
  }
}
</script>

<style lang="less" scoped>
.container {
  .component {
    padding-left: 10px;
    padding-bottom: 20px;
    .list {
      display: flex;
      flex-wrap: wrap;
    }
    .content-title {
      width: 99%;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      padding: 15px 0;
      border-bottom: 1px solid rgb(223, 220, 220);
      float: left;
      div {
        border-left: 4px solid rgb(1, 72, 226);
        padding-left: 5px;
      }
    }

    .deep {
      width: 100%;
      float: left;
    }
    .content-basic {
      width: 99%;
      min-height: 30px;
      float: left;
      padding: 10px 0;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .basic-div {
      min-width: 30%;
      min-height: 30px;
      float: left;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      font-size: 16px;
      /deep/.editStyle {
        .el-input__inner {
          background-color: #ffffcc;
        }
      }
      /deep/.textareaEditStyle {
        .el-textarea__inner {
          background-color: #ffffcc;
        }
      }
      /deep/ .radioEditStyle {
        .is-checked {
          background-color: #ffffcc;
        }
      }
    }
    .basic-child {
      float: none;
    }
    /deep/.el-radio__input {
      display: none !important;
    }
    .select-title {
      min-width: 150px;
      white-space: nowrap;
    }
    /deep/.radio {
      word-break: normal;
      .el-radio__input {
        display: none !important;
      }
      .el-checkbox-group,
      .el-radio-group {
        margin: 0;
      }
      .el-input__inner {
        appearance: none;
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 30px;
        line-height: 30px;
        outline: none;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
      }
      .is-bordered {
        padding: 12px 20px 0 10px;
        border-radius: 4px;
        box-sizing: border-box;
        height: 30px;
        line-height: 5px;
      }
      // 日期选择框
      .el-date-editor--date {
        .el-input__inner {
          padding: 0 30px;
        }
      }
      .el-checkbox {
        padding-top: 5px;
      }
      // 只读
      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
        border-color: #53565c;
      }
      .el-input.is-disabled .el-input__inner {
        background-color: #f5f7fa;
        border-color: #e4e7ed;
        color: #000;
        cursor: not-allowed;
      }
      .el-input .el-input__inner {
        color: #000;
      }
      .el-textarea .el-textarea__inner {
        color: #000;
      }
      .is-disabled .el-textarea__inner::placeholder {
        color: #000;
      }
      /* 谷歌 */
      .is-disabled .el-textarea__inner::-webkit-input-placeholder {
        color: #000;
      }
      /* 火狐 */
      .is-disabled .el-textarea__inner:-moz-placeholder {
        color: #000;
      }
      /*ie*/
      .is-disabled .el-textarea__inner:-ms-input-placeholder {
        color: #000;
      }

      .is-disabled .el-input__inner::placeholder {
        color: #000;
      }
      /* 谷歌 */
      .is-disabled .el-input__inner::-webkit-input-placeholder {
        color: #000;
      }
      /* 火狐 */
      .is-disabled .el-input__inner:-moz-placeholder {
        color: #000;
      }
      /*ie*/
      .is-disabled .el-input__inner:-ms-input-placeholder {
        color: #000;
      }
      .el-checkbox__input.is-disabled + span.el-checkbox__label {
        color: #53565c;
      }
      .el-radio.is-bordered.is-disabled.is-checked {
        border: 2px solid #53565c;
        .el-radio__label {
          color: #53565c;
        }
      }
      .el-radio__input.is-disabled + span.el-radio__label {
        color: #53565c;
      }
      .el-textarea.is-disabled {
        .el-textarea__inner {
          color: #000;
        }
      }
    }
    .div {
      width: 100%;
    }
    /deep/.el-checkbox-group,
    .el-radio-group {
      margin: 0;
    }
    .radio-textarea {
      width: 800px;
    }
    div {
      margin-right: 10px;
    }
    /deep/.el-input__inner {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 30px;
      line-height: 30px;
      outline: none;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }
    /deep/.is-bordered {
      padding: 12px 20px 0 10px;
      border-radius: 4px;
      box-sizing: border-box;
      height: 30px;
      line-height: 5px;
    }
  }

  .input_has_unit {
    margin-left: 0px !important;
  }
}
</style>
