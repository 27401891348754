var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"container",attrs:{"element-loading-text":"正在保存，请稍等。。。","element-loading-spinner":"el-icon-loading"}},[_c('div',{staticClass:"crt-content",staticStyle:{"background":"#fff"}},[_c('h3',{staticStyle:{"margin-left":"20px","display":"flex","align-items":"center"}},[_c('el-button',{staticClass:"commonBtn",attrs:{"type":"primary","size":"mini","icon":"el-icon-edit"},on:{"click":_vm.changeBasicInfo}},[_vm._v(" "+_vm._s(_vm.isResearch ? '编辑' : '取消')+" ")]),(!_vm.isResearch)?_c('el-button',{staticClass:"commonBtn",attrs:{"disabled":_vm.disabled,"type":"success","size":"mini","icon":"el-icon-edit"},on:{"click":_vm.saveBasicInfo}},[_vm._v(" 保存 ")]):_vm._e()],1)]),_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"component"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"basic-div"},[_c('div',{staticClass:"select-title"},[_vm._v("死亡日期：")]),_c('div',{staticClass:"radio"},[_c('el-date-picker',{class:_vm.queryDeathEventInfo.deathDate &&
                _vm.queryDeathEventInfo.deathDate !== _vm.isEditInfo.deathDate
                  ? 'editStyle'
                  : '',attrs:{"type":"date","placeholder":"选择日期","clearable":"","align":"center","value-format":"yyyy-MM-dd","format":"yyyy-MM-dd","disabled":_vm.isResearch},model:{value:(_vm.queryDeathEventInfo.deathDate),callback:function ($$v) {_vm.$set(_vm.queryDeathEventInfo, "deathDate", $$v)},expression:"queryDeathEventInfo.deathDate"}})],1)]),_c('div',{staticClass:"deep"}),_c('div',{staticClass:"deep"}),_c('div',{staticClass:"deep"}),_c('div',{staticClass:"basic-div"},[_c('div',{staticClass:"select-title"},[_vm._v("死亡原因：")]),_c('div',{staticClass:"radio"},[_c('el-input',{class:_vm.queryDeathEventInfo.deathCause &&
                _vm.queryDeathEventInfo.deathCause !== _vm.isEditInfo.deathCause
                  ? 'textareaEditStyle'
                  : '',attrs:{"type":"textarea","autosize":{ minRows: 2, maxRows: 4 },"maxlength":"100","placeholder":"请输入","disabled":_vm.isResearch},model:{value:(_vm.queryDeathEventInfo.deathCause),callback:function ($$v) {_vm.$set(_vm.queryDeathEventInfo, "deathCause", $$v)},expression:"queryDeathEventInfo.deathCause"}})],1)]),_c('div',{staticClass:"deep"}),_c('div',{staticClass:"basic-div"},[_c('div',{staticClass:"select-title"},[_vm._v("备注：")]),_c('div',{staticClass:"radio radio-textarea"},[_c('el-input',{class:_vm.queryDeathEventInfo.note && _vm.queryDeathEventInfo.note !== _vm.isEditInfo.note
                  ? 'textareaEditStyle'
                  : '',attrs:{"type":"textarea","autosize":{ minRows: 2, maxRows: 4 },"maxlength":"100","placeholder":"请输入","disabled":_vm.isResearch},model:{value:(_vm.queryDeathEventInfo.note),callback:function ($$v) {_vm.$set(_vm.queryDeathEventInfo, "note", $$v)},expression:"queryDeathEventInfo.note"}})],1)]),_c('div',{staticClass:"deep"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }